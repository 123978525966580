.wrapper
    width: 100%
    @include ltr-rtl("padding-left", var(--cui-sidebar-occupy-start, 0))
    will-change: auto
    @include transition(padding .15s)

// Make modal transparent and spinner larger
.modal--global-loader
    .modal-dialog
        .modal-content
            border: 0
            background: transparent
            align-items: center
            .spinner-border
                height: 100px
                width: 100px