@use "sass:color"

@mixin pulse-glow($color)
    $animation-name: unique-id()
    @keyframes #{$animation-name}
        from 
            filter: drop-shadow( 0 0 5px $color) drop-shadow( 0 0 15px $color) drop-shadow( 0 0 20px $color)
        to 
            filter: drop-shadow( 0 0 10px $color) drop-shadow( 0 0 20px $color) drop-shadow( 0 0 30px $color)
    animation-duration: 1s
    animation-name: $animation-name
    animation-iteration-count: infinite
    animation-direction: alternate

@mixin drop-glow($color)
    filter: drop-shadow( 0 0 5px $color) drop-shadow( 0 0 15px $color) drop-shadow( 0 0 20px $color)

.status-icon
    margin-right: 0.5rem
    color: #FAF9F6
    &--red .ci-primary
        @include pulse-glow(red)
    &--blue .ci-primary
        @include pulse-glow(blue)
    &--green .ci-primary
        @include drop-glow(green)
    &--yellow .ci-primary
        @include drop-glow(yellow)
    &--black .ci-primary
        @include drop-glow(black)
    &--gray .ci-primary
        @include pulse-glow(gray)
