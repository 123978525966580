// Here you can add other styles
.card
    box-shadow: 0 1px 1px 0 rgba(var(--cui-elevation-base-color,60,75,100),.14),0 2px 1px -1px rgba(var(--cui-elevation-base-color,60,75,100),.12),0 1px 3px 0 rgba(var(--cui-elevation-base-color,60,75,100),.2)

// Override for date picker to conform to CoreUI style
.rs-picker-toggle
    border-radius: 0px !important
    height: 38px !important

a:hover, a:focus
    text-decoration: initial

.error-screen
    background-color: black
    padding: 300px 200px

.error-logo-container
    max-width: 30%

// for the rsuite DatePicker menue to be visible even on top of CModal
.rs-picker-menu
    z-index: 1056

.toaster-center-x
    left: 30%

.toaster-wide
    min-width: 30%

.pointer-cursor
    cursor: pointer

.delayed__button
  height: 30px
  width: 30px
  background-color: #E8E8E8
  border-radius: 15px
  line-height: 30px
  text-align: center
  font-size: large
  cursor: default

.delayed__number
  width: 64px
  height: 30px
  line-height: 30px
  background-color: #E8E8E8
  text-align: center
  font-size: large

.blue-text
    color: blue

.dashboard-container
    background-color: #FFF
    border-radius: 10px
    margin: 4px

.connectorSvg 
    max-width: 100px
    max-height: 200px

// .list-group-item
//     border: unset

.sidebar-connectors
    display: flex 
    align-items: center
    max-width: 30%
    min-width: 30%
    overflow: hidden

.connector-status
    text-transform: lowercase
    font-size: 0.8rem
    display: inline-block
    padding: 7px
    max-height: 7px
    border-radius: 10px
    text-align: center
    font-weight: 600
    margin-left: 5px

    &.AVAILABLE
        background: #22e8ca

    &.PREPARING, &.CHARGING, &.FINISHING, &.RESERVING
        background: #0078d3

    &.UNAVAILABLE, &.UNREGISTERED
        background: gold

    &.SUSPENDEDEV, &.SUSPENDEDEVSE, &.FAULTED
        background: #5a959a

    &.DISCONNECTED
        background: #EA5455

    &.UNHEALTHY
        background: #FFA500

.error-message
    font-size: 14px
    color: red

.page-jump-label
    color: #321fdb

.page-jump-input
    max-width: 60px

.overflowing-table + div
  overflow: visible
