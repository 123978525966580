.black-border-bottom
    border-bottom: solid black

.black-border-right
    border-right: 1px solid black

.info-row-header
    color: var(--cui-dark-grey-info)
    min-width: 12vw

.black-text
    color: black
    text-decoration: none

