.session-detail-row
    border-bottom: solid var(--cui-border-color)
    border-bottom-width: thin

.transaction-amount-due
    border-top: solid var(--cui-border-color)
    border-top-width: thin

.sessions-float-label>label
    color: black
    font-size: 14px
    font-family: LufgaMedium

.sessions-summary
    font-size: 24px
    font-weight: 300

.session-tou-item
    font-size: 16px

.session-transaction-item
    font-size: 16px

.session-timeline-item
    font-size: 16px
